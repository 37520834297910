import React, { useState, useRef, useEffect, useCallback } from "react";
import "./../main.css";
import dlrlogo from "./../assets/images/logo.png";
import amulyamlogo from './../assets/images/amulyamlogo.jpeg';
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXmark,
  faUser,
  faTags,
  faPercent,
  faCheckToSlot,
  faWifi,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import {
  faUser as faRegularUser,
  faCreditCard,
} from "@fortawesome/free-regular-svg-icons";
import roomimg from "./../assets/images/room.png";
import hillview from './../assets/images/hillview.png';
import food from './../assets/images/food.png';
import card1 from './../assets/images/card1.gif';
import card2 from './../assets/images/card2.png';
import card3 from './../assets/images/card3.png';
import card4 from './../assets/images/card4.png';
import card5 from './../assets/images/card5.png';

import verify1 from './../assets/images/verify1.gif';
import verify2 from './../assets/images/verify2.svg';
import verify3 from './../assets/images/verify3.png';

import axios from "axios";
import { apiUrl, assetsUrl } from "../config/Config";

import Spinner from "react-activity/dist/Spinner";

import { Button, Modal } from "react-bootstrap";

import useRazorpay from "react-razorpay";
import Swal from 'sweetalert2';

import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
  const citieslist = [
    { value: "Tirupati", label: "Tirupati" },
    { value: "Chennai", label: "Chennai" },
  ];
  const today = new Date().toISOString().split('T')[0];
  const tomorrow = new Date();
tomorrow.setDate(tomorrow.getDate() + 1);
const endDate = tomorrow.toISOString().split('T')[0];
  const ageoption = [
    { value: "0-1", label: "0-1" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
  ];
  const steps = [
    "search",
    "room1",
    "enhance",
    "guest"
  ];
  const [stepsarr, setstepsarr] = useState(steps);
  const [currentsteps, setcurrentsteps] = useState("search");
  const [allowedsteps, setallowedsteps] = useState(1);
  const [citydata, setcitydata] = useState([]);
  const [selectedcity, setselectedcity] = useState("");
  const [hotelsdata, sethotelsdata] = useState([]);
  const [selectedhotel, setselectedhotel] = useState("");
  const [hoteldetails, sethoteldetails] = useState({});
  const [roomsdata, setroomsdata] = useState([
    {
      adults: { count: 1, minimum: 1, maximum: 5 },
      childrens: { minimum: 0, maximum: 4, count: [] },
    },
  ]);
  const [startdate, setstartdate] = useState(today);
  const [enddate, setenddate] = useState(endDate);
  const [forceupdate, setforceupdate] = useState(false);
  const [activetab, setactivetab] = useState(1);

  const [availabilityloader, setavailabilityloader] = useState(false);
  const [availableroomsarr, setavailableroomsarr] = useState([]);
  const [enhancementdata, setenhancementdata] = useState([]);
  const [selectedroomsdata, setselectedroomsdata] = useState([]);

  const availableroomsarrRef = useRef([]);
  availableroomsarrRef.current = availableroomsarr;

  const roomsdataRef = useRef([]);
  roomsdataRef.current = roomsdata;

  const stepsarrRef = useRef([]);
  stepsarrRef.current = stepsarr;

  const enhancementdataRef = useRef();
  enhancementdataRef.current = enhancementdata;

  const selectedroomsdataRef = useRef();
  selectedroomsdataRef.current = selectedroomsdata;

  // checkout page
  const [selectedOption, setSelectedOption] = useState("25");

  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [gstnumber, setgstnumber] = useState("");
  const [message, setmessage] = useState("");

  const [enhancetotal, setenhancetotal] = useState(0);
  const [subtotal, setsubtotal] = useState(0);
  const [taxtotal, settaxtotal] = useState(0);
  const [discounttotal, setdiscounttotal] = useState(0);
  const [completetotal, setcompletetotal] = useState(0);

  const [Razorpay, isLoaded] = useRazorpay();

  const [showroomdetailsmodal, setshowroomdetailsmodal] = useState(null);
  const [modalsubtotal, setmodalsubtotal] = useState(0);
  const [modaltaxamount, setmodaltaxamount] = useState(0);
  const [modaltitle, setmodaltitle] = useState("");
  const [modalvarianttitle, setmodalvarianttitle] = useState("");

  const [showenhancedetailsmodal, setshowenhancedetailsmodal] = useState(null);
  const [modalenhancetitle, setmodalenhancetitle] = useState("");
  const [modalenhancedesc, setmodalenhancedesc] = useState("");

  const booknowfinalbtn = () => {
    
    if (name && email && number) {
      
      var amount = 1;
      if (selectedOption == "25") {
        amount = ((25 / 100) * completetotal).toFixed(2);
      } else {
        amount = completetotal.toFixed(2);
      }
      displayRazorpay(amount)
    } else {
      Swal.fire({
        title: 'Failed',
        text: 'Please fill all mandatory fields',
        icon: 'error',
        customClass: {
          icon: 'custom-swal-icon' // Apply the custom class
        },
        width: '400px',
        confirmButtonText: 'Ok'
      });
    }

    
  }

  // console.log('selectedhotel >>', selectedhotel);

  const paymentsuccessfunction = async(paidamount, paymentid) => {
    var prevenhancedata = enhancementdataRef?.current;
    var selectedenhance = [];
    for(var i = 0; i < prevenhancedata?.length; i++) {
      var count = typeof prevenhancedata[i].count == 'number' ? prevenhancedata[i].count : parseInt(prevenhancedata[i].count);
      if(count > 0) {
        var obj = {};
        obj['id'] = prevenhancedata[i].id;
        obj['title'] = prevenhancedata[i].title;
        obj['count'] = count;
        selectedenhance.push(obj);
      }
    }

    var data = {
      name: name,
      email: email,
      number: number,
      gstnumber: gstnumber,
      special_request: message,
      totalamount: (completetotal).toFixed(2),
      paidamount: paidamount,
      startdate: startdate,
      enddate: enddate,
      totalnights: dateDifference(startdate, enddate),
      cityid: selectedcity?.value,
      hotelid: selectedhotel?.value,
      enhancement: selectedenhance,
      selectedrooms: selectedroomsdataRef.current,
      promocode: "",
      promoamount: "",
      paymentid: paymentid,
      subtotal: subtotal.toFixed(2),
      taxtotal: taxtotal.toFixed(2)
    }

    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "createbooking",data, headers)
      .then((res) => {
        if (res.data.status == "success") {

          var data = res.data;

          Swal.fire({
            title: 'Booked Successfully!',
            text: 'You will receive a booking details via email',
            icon: 'success',
            customClass: {
              icon: 'custom-swal-icon'
            },
            width: '400px',
            confirmButtonText: 'Ok'
          });


        } else {
          Swal.fire({
            title: 'Failed',
            text: res.data.message,
            icon: 'error',
            customClass: {
              icon: 'custom-swal-icon' // Apply the custom class
            },
            width: '400px',
            confirmButtonText: 'Ok'
          });
        }
      })
      .catch((err) => {
        alert(err.message);
      });

    // console.log('data >>', data);
  }

  const handletabstoggle = (index, rindex, num) => {
    // console.log("num >>>", num);
    // setactivetab(num);
    // setforceupdate(!forceupdate);
    var prevdata =  availableroomsarrRef.current
    prevdata[index]['rooms'][rindex]['activetab'] = num;
    // console.log('prevdata >>', prevdata);
    setavailableroomsarr(prevdata);
    setforceupdate(!forceupdate);
  };

  const handlechangecurrentstep = (num) => {
    var checkindex = stepsarrRef.current.indexOf(num) + 1
    if (checkindex > allowedsteps) {
    } else {
      setcurrentsteps(num);
    }
  };

  const clickcheckavailability = async() => {
    setstepsarr(steps)
  setavailabilityloader(true);
    var data = JSON.stringify({
      city: selectedcity?.value,
      hotel: selectedhotel?.value,
      startdate: startdate,
      enddate: enddate,
      allrooms: roomsdataRef?.current
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "checkroomavailability",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          var data = res.data.data;
          // console.log('checkroomavailability data >>>', data);
          if(data?.length > 0) {
            setavailableroomsarr(data);
            // splice and append room numbers
            var prevsteps = stepsarrRef.current;
            var selectedroomarr = [];
            for(var i = 0; i < data?.length; i++) {
              if(i == 0) {
                prevsteps.splice(1,1, 'room' + parseInt(i + 1));
              } else {
                prevsteps.splice(parseInt(1 + i),0, 'room' + parseInt(i + 1));
              }
              var obj = {};
              obj['roomid'] = "";
              obj['title'] = "";
              obj['hotelid'] = "";
              obj['tax'] = "";
              obj['variant'] = "";
              obj['price'] = "";
              obj['offerprice'] = "";
              obj['adults'] = "";
              obj['childrens'] = "";
              selectedroomarr.push(obj)
            }
            setselectedroomsdata(selectedroomarr)
            setstepsarr(prevsteps);
            setallowedsteps(2);
            setcurrentsteps('room1');
          }
          setavailabilityloader(false);
        } else {
         setavailabilityloader(false);
        }
      })
      .catch((err) => {
        alert(err.message);
      });

      setavailabilityloader(false);

      var edata = JSON.stringify({
        hotel: 1,
      });
      let resp1 = await axios
      .post(apiUrl + "gethotelsenhancement",edata, headers)
      .then((res) => {
        // console.log('gethotelsenhancement res >>', res.data.data)
        setenhancementdata(res.data.data);
      })
      .catch((err) => {
        alert('err >>',err.message);
      })
  };

  // console.log('stepsarrRef.current >>', stepsarrRef.current);

  const booknowclick = (num, index, roomindex, rateindex) => {
    // console.log('selectedroomsdataRef.current >>', selectedroomsdataRef.current);

    var prevdata = availableroomsarrRef.current[index]['rooms'][roomindex];
    var variantdata = JSON.parse(prevdata['description'])[rateindex];
    var prevroomsarr = roomsdataRef.current[index];
    // console.log('prevdata >>', prevdata);
    // console.log('variantdata >>', variantdata);
    // console.log('prevroomsarr >>', prevroomsarr);

    var prevselectedroomsdata = selectedroomsdataRef.current;
    prevselectedroomsdata[index]['roomid'] = prevdata['id'];
    prevselectedroomsdata[index]['title'] = prevdata['title'];
    prevselectedroomsdata[index]['hotelid'] = prevdata['hotelid'];
    prevselectedroomsdata[index]['tax'] = prevdata['tax'];
    prevselectedroomsdata[index]['variant'] = variantdata['ratetitle'];
    prevselectedroomsdata[index]['price'] = variantdata['price'];
    prevselectedroomsdata[index]['offerprice'] = variantdata['offerprice'];
    prevselectedroomsdata[index]['adults'] = prevroomsarr['adults']['count'];
    prevselectedroomsdata[index]['childrens'] = prevroomsarr['childrens']['count']?.length;



    var nexttabvalue = stepsarrRef.current[num + 1];
    var prevallowedstep = allowedsteps + 1;
    setallowedsteps(prevallowedstep);
    setcurrentsteps(nexttabvalue);

    setselectedroomsdata(prevselectedroomsdata);
  }

  // console.log('selectedroomsdataRef.current >>', selectedroomsdataRef.current);

  const enhancebtnclick = () => {

    // amount calculation part
    var roomsdata = selectedroomsdataRef.current;
    var enhancedata = enhancementdataRef.current;
    var differencedates = dateDifference(startdate, enddate);
    // console.log('roomsdata >>', roomsdata);
    // console.log('enhancedata >>', enhancedata);
    // console.log('differencedates >>', differencedates);

    var totalOfferRoomAmount = (differencedates * roomsdata.reduce((sum, current) => sum + parseInt(current.offerprice), 0));
    var totalPriceRoomAmount = (differencedates * roomsdata.reduce((sum, current) => sum + (parseInt(current.price) - parseInt(current.offerprice)), 0));
    var totalEnhanceAmount = enhancedata.reduce((sum, current) => sum + (current.count * parseInt(current.price)), 0);
    var totalTaxRoomAmount = (differencedates * roomsdata.reduce((sum, current) => sum + ((parseInt(current.tax) / 100) * parseInt(current.offerprice)), 0));

    var SubtotalAmount = totalOfferRoomAmount + totalEnhanceAmount;

    // console.log('totalOfferRoomAmount >>', totalOfferRoomAmount);
    // console.log('totalPriceRoomAmount >>', totalPriceRoomAmount);
    // console.log('totalEnhanceAmount >>', totalEnhanceAmount);
    // console.log('SubtotalAmount >>', SubtotalAmount);
    // console.log('totalTaxRoomAmount >>', totalTaxRoomAmount);

    setenhancetotal(totalEnhanceAmount);
    setsubtotal(SubtotalAmount);
    settaxtotal(totalTaxRoomAmount);
    setdiscounttotal(totalPriceRoomAmount);
    setcompletetotal(SubtotalAmount + totalTaxRoomAmount);


    // Redirect part
    var nexttabvalue = stepsarrRef.current.indexOf('guest');
    var prevallowedstep = nexttabvalue + 1;
    setallowedsteps(prevallowedstep);
    setcurrentsteps('guest');
  }

  const skipbtnclicked = () => {
    var prevdata = enhancementdataRef.current;
    var newarr = [];
    for(var i = 0; i < prevdata?.length; i++) {
      var obj = prevdata[i];
      obj['count'] = 0;
      newarr.push(obj);
    }
    setenhancementdata(newarr);

    enhancebtnclick()

    // var nexttabvalue = stepsarrRef.current.indexOf('guest');
    // var prevallowedstep = nexttabvalue + 1;
    // setallowedsteps(prevallowedstep);
    // setcurrentsteps('guest');
  }

  const removeroomfunc = (index) => {
    var prevdata = roomsdataRef.current;
    prevdata.splice(index, 1);
    setroomsdata(prevdata);
    setforceupdate(!forceupdate);
  };

  const handleaddroom = () => {
    var prevdata = roomsdataRef.current;
    if(prevdata?.length < hoteldetails?.maxrooms) {
      var obj = {
        adults: { count: 1, minimum: 1, maximum: 5 },
        childrens: { minimum: 0, maximum: 4, count: [] },
      };
      prevdata.push(obj);
      setroomsdata(prevdata);
      setforceupdate(!forceupdate);
    }
    
  };

  const handlepluschildrens = (index) => {
    var prevdata = roomsdataRef.current;
    if (
      prevdata[index]["childrens"]["count"].length <
      prevdata[index]["childrens"]["maximum"]
    ) {
      var obj = { age: "1" };
      prevdata[index]["childrens"]["count"].push(obj);
      setroomsdata(prevdata);
      setforceupdate(!forceupdate);
    }
  };

  const handleminuschildrens = (index) => {
    var prevdata = roomsdataRef.current;
    if (
      prevdata[index]["childrens"]["count"].length >
      prevdata[index]["childrens"]["minimum"]
    ) {
      prevdata[index]["childrens"]["count"].pop();
      setroomsdata(prevdata);
      setforceupdate(!forceupdate);
    }
  };

  const handleplusadults = (index) => {
    var prevdata = roomsdataRef.current;
    if (
      prevdata[index]["adults"]["count"] < prevdata[index]["adults"]["maximum"]
    ) {
      prevdata[index]["adults"]["count"] =
        prevdata[index]["adults"]["count"] + 1;
      setroomsdata(prevdata);
      setforceupdate(!forceupdate);
    }
  };

  const handleminusadults = (index) => {
    var prevdata = roomsdataRef.current;
    if (
      prevdata[index]["adults"]["count"] > prevdata[index]["adults"]["minimum"]
    ) {
      prevdata[index]["adults"]["count"] =
        prevdata[index]["adults"]["count"] - 1;
      setroomsdata(prevdata);
      setforceupdate(!forceupdate);
    }
  };

  const fetchallcities = async() => {
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .get(apiUrl + "getcitieslist", headers)
      .then((res) => {
        if (res.data.status == "success") {
          var data = res.data.data;
          var newarr = [];
          if (data?.length > 0) {
            for (var i = 0; i < data?.length; i++) {
              var obj = {};
              obj["value"] = data[i].id;
              obj["label"] = data[i].cityname;
              newarr.push(obj);
            }
            if(data?.length == 1) {
              setselectedcity(newarr[0])
            }
            setcitydata(newarr);
          }
        } else {
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const fetchcityhotels = async(city) => {
    var data = JSON.stringify({
      city: city,
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "gethotelslist",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          var data = res.data.data;
          var newarr = [];
          if (data?.length > 0) {
            for (var i = 0; i < data?.length; i++) {
              var obj = {};
              obj["value"] = data[i].id;
              obj["label"] = data[i].name;
              newarr.push(obj);
            }
            if(data?.length == 1) {
              setselectedhotel(newarr[0])
            }
            sethotelsdata(newarr);
          }
        } else {
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const fetchhoteldescription = async(hotel) => {
    var data = JSON.stringify({
      hotel: hotel,
    });
    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let resp = await axios
      .post(apiUrl + "gethotelsdesc",data, headers)
      .then((res) => {
        if (res.data.status == "success") {
          var data = res.data.data;
          // console.log('data >>', data[0])
          sethoteldetails(data[0])
          // var newarr = [];
          // if (data?.length > 0) {
          //   for (var i = 0; i < data?.length; i++) {
          //     var obj = {};
          //     obj["value"] = data[i].id;
          //     obj["label"] = data[i].name;
          //     newarr.push(obj);
          //   }
          //   if(data?.length == 1) {
          //     setselectedhotel(newarr[0])
          //   }
          //   sethotelsdata(newarr);
          // }
        } else {
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }

  const handleplusenhancement = (index) => {
    var prevdata = enhancementdataRef.current;
    if(parseInt(prevdata[index]['count']) < parseInt(prevdata[index]['maxlimit'])) {
      prevdata[index]['count'] = parseInt(prevdata[index]['count'])  + 1;
    setenhancementdata(prevdata);
    setforceupdate(!forceupdate);
    }
    
  }

  const handleminusenhancement = (index) => {
    var prevdata = enhancementdataRef.current;
    if(parseInt(prevdata[index]['count']) == 0) {

    } else {
      prevdata[index]['count'] = parseInt(prevdata[index]['count']) - 1;
      setenhancementdata(prevdata);
      setforceupdate(!forceupdate);
    }
   
  }

  const fetchallgetapis = async() => {
    await fetchallcities()
  }

  const submitform = () => {
    // console.log('test >>');
  }

  const dateFormatConvert = (dateString) => {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Define an array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Define an array of day names
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    // Extract day, month, year, and day of the week
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
    const dayOfWeek = dayNames[date.getDay()];

    // Return the formatted date string
    return `${day} ${month} '${year}, ${dayOfWeek}`;
}

const dateDifference = (date1, date2) => {
  // Parse the date strings into Date objects
  const startDate = new Date(date1);
  const endDate = new Date(date2);

  // Calculate the difference in milliseconds
  const diffInMs = endDate - startDate;

  // Convert the difference from milliseconds to days
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  // Return the difference in days
  return Math.round(diffInDays);
}

const getnextdatefunc = (dt) => {
  const date = new Date(dt);

  // Add 1 day to the date
  date.setDate(date.getDate() + 1);
  
  // Get the next date in YYYY-MM-DD format
  const nextDate = date.toISOString().split('T')[0];
  return nextDate;
}



const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const displayRazorpay = async (amount) => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        const options = {
            key: 'rzp_live_Efc107IVgQWbD5', // Enter the Key ID generated from the Dashboard
            amount: amount * 100, // Amount is in paise (e.g., 50000 paise = 500 INR)
            currency: 'INR',
            name: 'Amulyam Residency',
            description: 'Book Your rooms',
            image: 'https://your-logo-url.com', // Optional
            handler: function (response) {
                // alert('Payment successful! >>>');
                // console.log('Payment successful! >>>', response);
                // console.log(response.razorpay_payment_id);
                paymentsuccessfunction(amount, response.razorpay_payment_id);
                // Handle the response here, like updating your database
            },
            prefill: {
                name: name,
                email: email,
                contact: number,
            },
            notes: {
                address: 'Your Company Address',
            },
            theme: {
                color: '#fff',
            },
            modal: {
              // Event handler when the user closes the payment modal
              ondismiss: function() {
                  // console.log('Payment process cancelled by the user.');
                  // Handle the cancellation
              }
          },
          payment_failed: function(response) {
            console.error(response.error);
            // Handle payment failure
        }

        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
        paymentObject.on('payment.failed', function(response) {
          // Handle payment failure
      });

      paymentObject.on('modal.close', function() {
          // Handle the cancellation
      });
    };

    const roomdetailsmodaldfunc = (tax, price, title, vtitle) => {
      // console.log('roomdetailsmodaldfunc tax >>', tax);
      // console.log('roomdetailsmodaldfunc price >>', price);
      var taxamount = (tax / 100) * price;
      setmodalsubtotal(parseInt(price));
      setmodaltaxamount(parseInt(taxamount));
      setmodaltitle(title);
      setmodalvarianttitle(vtitle);
      setshowroomdetailsmodal(true)
    }

    const enhancedetailsmodalfunc = (title, desc) => {
      setmodalenhancetitle(title);
      setmodalenhancedesc(desc);
      setshowenhancedetailsmodal(true)
    }

  useEffect(() => {
    if(selectedcity) {
      fetchcityhotels(selectedcity?.value);
    }
  }, [selectedcity])

  useEffect(() => {
    if(selectedhotel) {
      fetchhoteldescription(selectedhotel?.value);
    }
  }, [selectedhotel])

  useEffect(() => {
    fetchallgetapis();
    
  }, [])

  return (
    <div>
      <div className="mainheader">
        <div className="pagewidth">
          <img src={amulyamlogo} className="dlrlogo" />
        </div>
      </div>
      <div className="mainbody">
        <div className="pagewidth">
          <div className="bodybg">
            <p className="bookhoteltitle">Book Your Hotel</p>

            {/* search */}
            <div
              className="titleheadbox"
              onClick={() => {
                handlechangecurrentstep("search");
              }}
            >
              <p className="titleheadbox-title">1. Search</p>
            </div>
            <div
              className="descriptionbox"
              style={
                currentsteps == "search"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <div className="row">
                <div className="col-md-7">
                  <div className="row">
                    <div className="col-12 col-md-2">
                      <p className="inputlabel">Place</p>
                    </div>
                    <div className="col-12 col-md-10">
                      <div className="">
                        <Select
                          closeMenuOnSelect={true}
                          value={selectedcity}
                          onChange={setselectedcity}
                          options={citydata}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row rowmargintop">
                    <div className="col-12 col-md-2">
                      <p className="inputlabel">Hotel</p>
                    </div>
                    <div className="col-12 col-md-10">
                      <div className="">
                        <Select
                          closeMenuOnSelect={true}
                          value={selectedhotel}
                          onChange={setselectedhotel}
                          options={hotelsdata}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row rowmargintop">
                    <div className="col-12 col-md-2">
                      <p className="inputlabel">From</p>
                    </div>
                    <div className="col-12 col-md-10">
                      <div className="row">
                        <div className="col-12 col-md-5">
                          <div className="">
                            <input
                              value={startdate}
                              onChange={(t) => {
                                setstartdate(t.target.value);
                                setenddate(getnextdatefunc(t.target.value));
                              }}
                              type="date"
                              min={today}
                              className="form-control custominput"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-2">
                          <p
                            className="inputlabel"
                            style={{ textAlign: "center" }}
                          >
                            To
                          </p>
                        </div>
                        <div className="col-12 col-md-5">
                          <div className="">
                            <input
                              value={enddate}
                              onChange={(t) => {
                                setenddate(t.target.value);
                              }}
                              min={
                                startdate ? getnextdatefunc(startdate) : today
                              }
                              type="date"
                              className="form-control custominput"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* rooms */}

                  {roomsdataRef.current?.map((item, index) => {
                    return (
                      <div className="row rowmargintop" key={index}>
                        <div className="col-12 col-md-2">
                          <p className="inputlabel inputlabelroom">
                            Room {index + 1}
                          </p>
                        </div>
                        <div className="col-6 col-md-3">
                          <div className="adultsmaindiv">
                            <p className="labelsubhead">Adults</p>
                            <div className="adultsboxdiv">
                            <div
                                className="btn plusbox"
                                onClick={() => {
                                  handleminusadults(index);
                                }}
                              >
                                -
                              </div>
                              <div className="adultinput">
                                <p>{item["adults"]["count"]}</p>
                              </div>
                              
                              <div
                                className="btn plusbox"
                                onClick={() => {
                                  handleplusadults(index);
                                }}
                              >
                                +
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 col-md-3">
                          <div className="adultsmaindiv">
                            <p className="labelsubhead">Children</p>
                            <div className="adultsboxdiv1">
                              <div className="adultsboxdiv">
                              <div
                                  className="btn plusbox"
                                  onClick={() => {
                                    handleminuschildrens(index);
                                  }}
                                >
                                  -
                                </div>
                                <div className="adultinput">
                                  <p>{item["childrens"]["count"]?.length}</p>
                                </div>
                                <div
                                  className="btn plusbox"
                                  onClick={() => {
                                    handlepluschildrens(index);
                                  }}
                                >
                                  +
                                </div>
                               
                              </div>
                              {index > 0 ? (
                                <div
                                  className="closeicondiv"
                                  onClick={() => {
                                    removeroomfunc(index);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    className="iconstyles"
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="childrensagediv">
                            {item?.childrens?.count?.length > 0
                              ? item?.childrens?.count?.map((itm, idx) => {
                                  return (
                                    <div className="childrensagebox">
                                      <p className="labelsubhead1">
                                        Child {idx + 1}
                                      </p>
                                      <select className="form-control">
                                        {ageoption?.map((aitm, aidx) => {
                                          return (
                                            <option value={aitm?.value}>
                                              {aitm?.value}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="row rowmargintop">
                    <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-10">
                      <div className="anotherroom" onClick={handleaddroom}>
                        <p>+ Add Another Room</p>
                      </div>
                    </div>
                  </div>

                  <div className="row rowmargintop">
                    <div className="col-12 col-md-2"></div>
                    <div className="col-12 col-md-10">
                      <div
                        className="check-availability-btn"
                        onClick={() => {
                          clickcheckavailability();
                        }}
                      >
                        {availabilityloader ? (
                          <Spinner
                            color="#fff"
                            size={16}
                            className="homebtnspinner"
                          />
                        ) : (
                          <p>Check Availability</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  {
                    hoteldetails?.promotext ?
                    <div className="promotextbox">
                    <div
                      className="htmlcontent"
                      dangerouslySetInnerHTML={{
                        __html: hoteldetails?.promotext,
                      }}
                    />
                  </div> : null
                  }

                  {
                    hoteldetails?.description ?
                    <div className="promotextbox descriptionbox">
                    <div
                      className="htmlcontent"
                      dangerouslySetInnerHTML={{
                        __html: hoteldetails?.description,
                      }}
                    />
                  </div> : null
                  }
                  
                 
                </div>
              </div>
            </div>

            {/* rooms */}
            {availableroomsarrRef.current?.length > 0 ? (
              availableroomsarrRef.current?.map((item, index) => {
                var indexname = "room" + parseInt(index + 1);
                // console.log('indexname >>', indexname);
                // console.log('item >>>', item);
                var requiredroomdata = roomsdataRef.current[index];
                var fillednumber = requiredroomdata?.adults?.count;
                var filledchildnumber =
                  requiredroomdata?.childrens?.count?.length;
                // console.log('requiredroomdata >>', requiredroomdata);
                return (
                  <>
                    <div
                      className="titleheadbox"
                      onClick={() => {
                        handlechangecurrentstep(indexname);
                      }}
                    >
                      <p className="titleheadbox-title">
                        {" "}
                        {stepsarrRef.current.indexOf(indexname) + 1}. Select
                        Room {parseInt(index + 1)} ({fillednumber} Adult,{" "}
                        {filledchildnumber} Children)
                      </p>
                    </div>
                    <div
                      className="descriptionbox descriptionbox1"
                      style={
                        currentsteps == indexname
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      {item?.rooms?.length > 0
                        ? item?.rooms?.map((ritem, rindex) => {
                            var imagesarr = ritem.images;
                            // console.log('imagesarr >>', JSON.parse(imagesarr)[0]);
                            console.log('ritem >>', ritem);

                            var maxadultsarr = Array.from({
                              length: parseInt(ritem.maxadults),
                            });
                            var maxchildrenarr = Array.from({
                              length: parseInt(ritem.maxchildrens),
                            });
                            var ratesarr = ritem?.description
                              ? JSON.parse(ritem?.description)
                              : [];
                            
                              var sliderimagesarr = [];
                              if(imagesarr) {
                                sliderimagesarr= JSON.parse(imagesarr);
                              } else {

                              }
                            // console.log('ratesarr >>>', ratesarr);
                            return (
                              <div className="">
                                <div className="roomsmaindiv">
                                  <div className="roomflex">
                                    <img
                                      src={
                                        imagesarr
                                          ? assetsUrl +
                                            "roomimages/" +
                                            JSON.parse(imagesarr)[0]
                                          : ""
                                      }
                                      className="roomimg"
                                    />
                                    <div className="room-titlediv">
                                      <p className="room-title">
                                        {ritem?.title}
                                      </p>
                                      <div className="room-userdiv">
                                        <div className="room-useradultdiv">
                                          {maxadultsarr?.length > 0
                                            ? maxadultsarr?.map(
                                                (item, index) => {
                                                  if (index < fillednumber) {
                                                    return (
                                                      <FontAwesomeIcon
                                                        icon={faUser}
                                                        className="usericonstyles"
                                                      />
                                                    );
                                                  } else {
                                                    return (
                                                      <FontAwesomeIcon
                                                        icon={faRegularUser}
                                                        className="usericonstyles"
                                                      />
                                                    );
                                                  }
                                                }
                                              )
                                            : null}
                                        </div>
                                        <div className="room-userchildrendiv">
                                          {maxchildrenarr?.length > 0
                                            ? maxchildrenarr?.map(
                                                (item, index) => {
                                                  if (
                                                    index < filledchildnumber
                                                  ) {
                                                    return (
                                                      <FontAwesomeIcon
                                                        icon={faUser}
                                                        className="usericonstyles1"
                                                      />
                                                    );
                                                  } else {
                                                    return (
                                                      <FontAwesomeIcon
                                                        icon={faRegularUser}
                                                        className="usericonstyles1"
                                                      />
                                                    );
                                                  }
                                                }
                                              )
                                            : null}
                                        </div>
                                      </div>
                                      <p className="room-sizetext">
                                        Room Size :{" "}
                                        <span>{ritem?.roomsize} sq. ft.</span>
                                      </p>
                                    </div>
                                    <div className="room-pricediv">
                                      <div className="room-priceinnerdiv">
                                        <div className="offerpricepricediv">
                                          <p className="ratefrom">From</p>
                                          <div className="ratepricediv">
                                            <p className="originalprice">
                                              <s>{ratesarr[0]?.price}</s>
                                            </p>
                                            <p className="offerprice">
                                              {ratesarr[0]?.offerprice}
                                            </p>
                                          </div>
                                          <p className="per-night">
                                            <span>INR</span> / night
                                          </p>
                                        </div>
                                        <p className="plustax">Plus taxes</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="roomsdescdiv">
                                  <div className="tabmaindiv">
                                    <div
                                      className="tabmainbox"
                                      style={
                                        ritem?.activetab == 1
                                          ? { backgroundColor: "#fff" }
                                          : {}
                                      }
                                      onClick={() => {
                                        handletabstoggle(index, rindex, 1);
                                      }}
                                    >
                                      <p>Rates</p>
                                    </div>
                                    <div
                                      className="tabmainbox"
                                      style={
                                        ritem?.activetab == 2
                                          ? { backgroundColor: "#fff" }
                                          : {}
                                      }
                                      onClick={() => {
                                        handletabstoggle(index, rindex, 2);
                                      }}
                                    >
                                      <p>Amenities</p>
                                    </div>
                                    <div
                                      className="tabmainbox"
                                      style={
                                        ritem?.activetab == 3
                                          ? { backgroundColor: "#fff" }
                                          : {}
                                      }
                                      onClick={() => {
                                        handletabstoggle(index, rindex, 3);
                                      }}
                                    >
                                      <p>Photos</p>
                                    </div>
                                  </div>
                                  {ritem?.activetab == 1 ? (
                                    <div className="ratesbg">
                                      {ratesarr?.length > 0
                                        ? ratesarr?.map(
                                            (ratesitem, ratesindex) => {
                                              console.log(
                                                "ratesitem >>",
                                                ratesitem
                                              );
                                              var discountPercentage =
                                                ((ratesitem?.price -
                                                  ratesitem?.offerprice) /
                                                  ratesitem?.price) *
                                                100;
                                              return (
                                                <div
                                                  className="ratesarrdiv"
                                                  style={
                                                    ratesindex ==
                                                    ratesarr?.length - 1
                                                      ? { borderBottom: 0, marginBottom: 10, paddingBottom: 0 }
                                                      : {}
                                                  }
                                                >
                                                  <div className="dealsdiv">
                                                    <p className="deal-title">
                                                      {ratesitem?.ratetitle}
                                                    </p>
                                                    <FontAwesomeIcon
                                                      icon={faTags}
                                                      className="tagicon"
                                                    />
                                                    <p className="discount-title">
                                                      {Number.isInteger(
                                                        discountPercentage
                                                      )
                                                        ? discountPercentage
                                                        : discountPercentage.toFixed(
                                                            2
                                                          )}{" "}
                                                      % Off on room price
                                                    </p>
                                                  </div>
                                                  <div className="ratesmaindiv">
                                                    <div className="inclusivemaindiv">
                                                      <div
                                                        className="htmlcontent"
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            ratesitem?.ratedesc,
                                                        }}
                                                      />
                                                      <p
                                                        className="viewdetails"
                                                        onClick={() => {
                                                          roomdetailsmodaldfunc(
                                                            ritem?.tax,
                                                            ratesitem?.offerprice,
                                                            ritem?.title,
                                                            ratesitem?.ratetitle
                                                          );
                                                        }}
                                                      >
                                                        View Details
                                                      </p>
                                                    </div>
                                                    <div className="ratespricebooknowdiv">
                                                      <div className="room-priceinnerdiv">
                                                        <div className="offerpricepricediv">
                                                          {/* <p className="ratefrom">From</p> */}
                                                          <div className="ratepricediv">
                                                            <p className="originalprice">
                                                              <s>
                                                                {
                                                                  ratesitem?.price
                                                                }
                                                              </s>
                                                            </p>
                                                            <p className="offerprice">
                                                              {
                                                                ratesitem?.offerprice
                                                              }
                                                            </p>
                                                          </div>
                                                          <p className="per-night">
                                                            <span>INR</span> /
                                                            night
                                                          </p>
                                                        </div>
                                                        <p className="plustax">
                                                          Plus taxes
                                                        </p>
                                                      </div>
                                                      <div
                                                        className="booknowbtn"
                                                        onClick={() => {
                                                          booknowclick(
                                                            stepsarrRef.current.indexOf(
                                                              indexname
                                                            ),
                                                            index,
                                                            rindex,
                                                            ratesindex
                                                          );
                                                        }}
                                                      >
                                                        <p>Book Now</p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        : null}
                                    </div>
                                  ) : ritem?.activetab == 2 ? (
                                    <div className="ratesbg">
                                      {/* <p className="amenitiestext"></p> */}
                                      <div
                                        className="htmlcontent"
                                        dangerouslySetInnerHTML={{
                                          __html: ritem?.amenitiesdescription,
                                        }}
                                      />
                                    </div>
                                  ) : ritem?.activetab == 3 ? (
                                    <div className="ratesbg">
                                      <div className="carousalmaindiv">
                                        <div
                                          id={"carouselExampleControls"+index+"_"+rindex}
                                          className="carousel slide"
                                          data-ride="carousel"
                                        >
                                          <div className="carousel-inner">
                                            {
                                              sliderimagesarr?.map((item, index) => {
                                                var carousaltitle = index == 0 ? "carousel-item active" : "carousel-item";
                                                return (
                                                  <div className={carousaltitle} key={index}>
                                              <img
                                                src={assetsUrl +
                                                  "roomimages/" +
                                                  item}
                                                className="d-block w-100"
                                                alt="Slide 1"
                                              />
                                            </div>
                                                )
                                              })
                                            }
                                            
                                          </div>
                                          <a
                                            className="carousel-control-prev"
                                            // href={"#carouselExampleControls"+index+"_"+rindex}
                                            data-bs-target={"#carouselExampleControls"+index+"_"+rindex} data-bs-slide="prev"
                                            role="button"
                                            // data-slide="prev"
                                          >
                                            <span
                                              className="carousel-control-prev-icon"
                                              aria-hidden="true"
                                            ></span>
                                            <span className="sr-only">
                                              Previous
                                            </span>
                                          </a>
                                          <a
                                            className="carousel-control-next"
                                            data-bs-target={"#carouselExampleControls"+index+"_"+rindex} data-bs-slide="next"
                                            role="button"
                                            // data-slide="next"
                                          >
                                            <span
                                              className="carousel-control-next-icon"
                                              aria-hidden="true"
                                            ></span>
                                            <span className="sr-only">
                                              Next
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </>
                );
              })
            ) : (
              <div
                className="titleheadbox"
                onClick={() => {
                  handlechangecurrentstep("room1");
                }}
              >
                <p className="titleheadbox-title">2. Select Room</p>
              </div>
            )}

            {/* enhance */}
            <div
              className="titleheadbox"
              onClick={() => {
                handlechangecurrentstep("enhance");
              }}
            >
              <p className="titleheadbox-title">
                {stepsarrRef.current.indexOf("enhance") + 1}. Enhance Your Stay
              </p>
            </div>
            <div
              className="descriptionbox"
              style={
                currentsteps == "enhance"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <div className="enhancemaindiv">
                <div className="row">
                  {enhancementdataRef.current?.length > 0
                    ? enhancementdataRef.current?.map((item, index) => {
                        // console.log('enhance item >>', item);
                        return (
                          <div className="col-12 col-md-4" key={index}>
                            <div className="enhancebox">
                              <img
                                src={
                                  item?.image
                                    ? assetsUrl + "enhanceimages/" + item?.image
                                    : ""
                                }
                                className="hillviewimg"
                              />
                              <div className="enhanceboxdiv">
                                <p className="enhancetitle">{item.title}</p>
                                <p className="viewdetails" onClick={() => {enhancedetailsmodalfunc(item?.title, item?.description)}}>View Details</p>
                                <div className="adultsmaindiv mt-3">
                                  <div className="adultsboxdiv">
                                  <div
                                      className="btn plusbox"
                                      onClick={() => {
                                        handleminusenhancement(index);
                                      }}
                                    >
                                      -
                                    </div>

                                    <div className="adultinput">
                                      <p>{item.count}</p>
                                    </div>
                                   
                                    <div
                                      className="btn plusbox"
                                      onClick={() => {
                                        handleplusenhancement(index);
                                      }}
                                    >
                                      +
                                    </div>
                                    <div className="enhancemrpdiv">
                                      <div
                                        className=""
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                        }}
                                      >
                                        <p className="enhancemrptext">
                                          INR {parseInt(item.price).toFixed(2)}
                                        </p>
                                        {parseInt(item.count) > 0 ? (
                                          <p
                                            className="enhancemrptext"
                                            style={{ marginLeft: 5 }}
                                          >
                                            {" "}
                                            x {parseInt(item.count)}
                                          </p>
                                        ) : null}
                                      </div>
                                      {parseInt(item.count) > 0 ? (
                                        <p className="enhancemrptext">
                                          Total : INR{" "}
                                          {(
                                            parseInt(item.count) *
                                            parseInt(item.price)
                                          ).toFixed(2)}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : null}
                </div>
                <div className="enhanceskipdiv">
                  <p
                    className="skiptext"
                    onClick={() => {
                      skipbtnclicked();
                    }}
                  >
                    Skip
                  </p>
                  <div
                    className="booknowbtn enhancebtn"
                    onClick={() => {
                      enhancebtnclick();
                    }}
                  >
                    <p>Add Selected Services</p>
                  </div>
                </div>
              </div>
            </div>

            {/* guest */}
            <div
              className="titleheadbox"
              onClick={() => {
                handlechangecurrentstep("guest");
              }}
            >
              <p className="titleheadbox-title">
                {stepsarrRef.current.indexOf("guest") + 1}. Guest Information
              </p>
            </div>
            <div
              className="descriptionbox"
              style={
                currentsteps == "guest"
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <div className="">
                <div className="guestinfomaindiv">
                  <div className="guestinfoformdiv">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <input
                          type="text"
                          value={name}
                          onChange={(t) => {
                            setname(t.target.value);
                          }}
                          className="form-control guestinput"
                          placeholder="Full Name *"
                        />
                      </div>
                      <div className="col-6 col-md-6">
                        <input
                          type="text"
                          value={email}
                          onChange={(t) => {
                            setemail(t.target.value);
                          }}
                          className="form-control guestinput"
                          placeholder="Email Address *"
                        />
                      </div>
                      <div className="col-6 col-md-6">
                        <input
                          type="text"
                          value={number}
                          maxLength={10}
                          onChange={(t) => {
                            setnumber(t.target.value);
                          }}
                          className="form-control guestinput"
                          placeholder="Phone Number *"
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        <input
                          type="text"
                          value={gstnumber}
                          onChange={(t) => {
                            setgstnumber(t.target.value);
                          }}
                          className="form-control guestinput"
                          placeholder="GST Number (Optional)"
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        <textarea
                          type="text"
                          value={message}
                          onChange={(t) => {
                            setmessage(t.target.value);
                          }}
                          className="form-control guestinput guesttextarea"
                          placeholder="Special Request (Optional)"
                        ></textarea>
                      </div>
                    </div>
                    <div className="mobhide">
                    <div className="guestpreferencediv">
                      <div className="guestpreferencerow">
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <input
                              value={"25"}
                              onChange={(t) => {
                                setSelectedOption(t.target.value);
                              }}
                              checked={selectedOption === "25"}
                              type="radio"
                              id="percent25"
                              name="paypercent"
                              className="inputradiobutton"
                            />
                            <label for="percent25" className="prefertext">
                              I prefer to pay 25% now
                            </label>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="paynowrow">
                              <p className="pay-now-text">Pay Now</p>
                              <p className="pay-now-price">
                                INR {((25 / 100) * completetotal).toFixed(2)}
                              </p>
                            </div>
                            <div className="paynowrow paynowrow1">
                              <p className="pay-now-text">Pay Later</p>
                              <p className="pay-now-price pay-now-price1">
                                INR{" "}
                                {(
                                  completetotal -
                                  (25 / 100) * completetotal
                                ).toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="guestorrow">
                        <hr className="paymentoptionhr" />
                        <p className="paymentortext">or</p>
                        <hr className="paymentoptionhr" />
                      </div>
                      <div className="guestpreferencerow">
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <input
                              value={"100"}
                              onChange={(t) => {
                                setSelectedOption(t.target.value);
                              }}
                              checked={selectedOption === "100"}
                              type="radio"
                              id="percent25"
                              name="paypercent"
                              className="inputradiobutton"
                            />
                            <label for="percent25" className="prefertext">
                              I prefer to pay 100% now
                            </label>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="paynowrow">
                              <p className="pay-now-text">Pay Now</p>
                              <p className="pay-now-price">
                                INR {completetotal.toFixed(2)}
                              </p>
                            </div>
                            <div className="paynowrow paynowrow1">
                              <p className="pay-now-text">Pay Later</p>
                              <p className="pay-now-price pay-now-price1">
                                INR 0.00
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="bookyourstaytext">
                      Book your stay before the prices go up!
                    </p>
                    <p className="bycompletingtext">
                      By completing this reservation you are accepting our{" "}
                      <span className="termstext">Terms & Conditions</span>
                    </p>
                    <div
                      className="booknowbtn booknowbtn1"
                      onClick={() => {
                        booknowfinalbtn();
                      }}
                    >
                      <p>Book Now</p>
                    </div>
                    <div className="cardsarr">
                      <img src={card1} className="cardimg" />
                      <img src={card2} className="cardimg cardimg1" />
                      <img src={card3} className="cardimg cardimg1" />
                      <img src={card4} className="cardimg" />
                      <img src={card5} className="cardimg" />
                    </div>
                    </div>
                    
                  </div>
                  <div className="guestcheckindiv">
                    <div className="checkinrow">
                      <div className="checkinbox">
                        <p className="checkintext">Check In</p>
                        <p className="checkindate">
                          {dateFormatConvert(startdate)}
                        </p>
                      </div>
                      <div className="checkoutbox">
                        <p className="checkintext">Check Out</p>
                        <p className="checkindate">
                          {dateFormatConvert(enddate)}
                        </p>
                      </div>
                    </div>
                    <div className="guestdescrow">
                      <p className="guestlabel">No of nights</p>
                      <p className="guestvalue">
                        {dateDifference(startdate, enddate)} Night
                      </p>
                    </div>
                    {selectedroomsdataRef.current?.length > 0
                      ? selectedroomsdataRef.current?.map((item, index) => {
                          return (
                            <div key={index}>
                              <div className="guestdescrow">
                                <p className="guestlabel roombg">
                                  Room {index + 1}
                                </p>
                                <p className="guestvalue">{item?.title}</p>
                              </div>
                              <div className="guestdescrow">
                                <p className="guestlabel">Guests</p>
                                <p className="guestvalue">
                                  {item?.adults} Adult, {item?.childrens}{" "}
                                  Children
                                </p>
                              </div>
                            </div>
                          );
                        })
                      : null}

                    <div>
                      <div className="guestdescrow">
                        <p className="guestlabel roombg">Enhance Your Stay</p>
                        <p className="guestvalue"></p>
                      </div>
                    </div>

                    {enhancementdataRef?.current?.map((item, index) => {
                      // console.log("enhancement item >>", item);
                      var count =
                        typeof item.count == "number"
                          ? item.count
                          : parseInt(item.count);
                      if (count > 0) {
                        return (
                          <div key={index}>
                            <div className="guestdescrow">
                              <p className="guestlabel">{item?.title}</p>
                              <p className="guestvalue">{count} unit</p>
                            </div>
                          </div>
                        );
                      }
                    })}

                    <div className="grandtotalbg">
                      {selectedroomsdataRef.current?.length > 0
                        ? selectedroomsdataRef.current?.map((item, index) => {
                            return (
                              <div
                                className="guestdescrow guestfaretablerow"
                                key={index}
                              >
                                <p className="guestlabel">
                                  <FontAwesomeIcon
                                    icon={faAngleRight}
                                    className="guestangleright"
                                  />{" "}
                                  {item.variant} - Room {index + 1}
                                </p>
                                <p className="guestvalue">
                                  INR{" "}
                                  {(
                                    dateDifference(startdate, enddate) *
                                    parseInt(item?.offerprice)
                                  ).toFixed(2)}
                                </p>
                              </div>
                            );
                          })
                        : null}
                      <div className="guestdescrow guestfaretablerow">
                        <p className="guestlabel">
                          <FontAwesomeIcon
                            icon={faAngleRight}
                            className="guestangleright"
                          />{" "}
                          Enhance Your Stay
                        </p>
                        <p className="guestvalue">
                          INR {enhancetotal.toFixed(2)}
                        </p>
                      </div>

                      <div className="guestdescrow">
                        <p className="guestlabel">Sub Total</p>
                        <p className="guestvalue">INR {subtotal.toFixed(2)}</p>
                      </div>
                      <div className="guestdescrow">
                        <p className="guestlabel">Taxes and Fees</p>
                        <p className="guestvalue">INR {taxtotal.toFixed(2)}</p>
                      </div>
                      <hr className="grandtotalbghr" />
                      <div
                        className="guestdescrow"
                        style={{ justifyContent: "flex-start" }}
                      >
                        <p className="grandtotaltext">Grand Total</p>
                        <p className="inrtext">INR</p>
                        <p className="mrptext">
                          <s>{(completetotal + discounttotal).toFixed(2)}</s>
                        </p>
                        <p className="inrtext" style={{ marginLeft: 0 }}>
                          {completetotal.toFixed(2)}
                        </p>
                      </div>
                      <p className="bookyourstaytext">
                        You are saving{" "}
                        <span>INR {discounttotal.toFixed(2)}</span> on this
                        deal!
                      </p>
                    </div>
                  </div>
                </div>
                <div className="deskhide">
                    <div className="guestpreferencediv">
                      <div className="guestpreferencerow">
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <input
                              value={"25"}
                              onChange={(t) => {
                                setSelectedOption(t.target.value);
                              }}
                              checked={selectedOption === "25"}
                              type="radio"
                              id="percent25"
                              name="paypercent"
                              className="inputradiobutton"
                            />
                            <label for="percent25" className="prefertext">
                              I prefer to pay 25% now
                            </label>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="paynowrow">
                              <p className="pay-now-text">Pay Now</p>
                              <p className="pay-now-price">
                                INR {((25 / 100) * completetotal).toFixed(2)}
                              </p>
                            </div>
                            <div className="paynowrow paynowrow1">
                              <p className="pay-now-text">Pay Later</p>
                              <p className="pay-now-price pay-now-price1">
                                INR{" "}
                                {(
                                  completetotal -
                                  (25 / 100) * completetotal
                                ).toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="guestorrow">
                        <hr className="paymentoptionhr" />
                        <p className="paymentortext">or</p>
                        <hr className="paymentoptionhr" />
                      </div>
                      <div className="guestpreferencerow">
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <input
                              value={"100"}
                              onChange={(t) => {
                                setSelectedOption(t.target.value);
                              }}
                              checked={selectedOption === "100"}
                              type="radio"
                              id="percent25"
                              name="paypercent"
                              className="inputradiobutton"
                            />
                            <label for="percent25" className="prefertext">
                              I prefer to pay 100% now
                            </label>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="paynowrow">
                              <p className="pay-now-text">Pay Now</p>
                              <p className="pay-now-price">
                                INR {completetotal.toFixed(2)}
                              </p>
                            </div>
                            <div className="paynowrow paynowrow1">
                              <p className="pay-now-text">Pay Later</p>
                              <p className="pay-now-price pay-now-price1">
                                INR 0.00
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="bookyourstaytext">
                      Book your stay before the prices go up!
                    </p>
                    <p className="bycompletingtext">
                      By completing this reservation you are accepting our{" "}
                      <span className="termstext">Terms & Conditions</span>
                    </p>
                    <div
                      className="booknowbtn booknowbtn1"
                      onClick={() => {
                        booknowfinalbtn();
                      }}
                    >
                      <p>Book Now</p>
                    </div>
                    <div className="cardsarr">
                      <img src={card1} className="cardimg" />
                      <img src={card2} className="cardimg cardimg1" />
                      <img src={card3} className="cardimg cardimg1" />
                      <img src={card4} className="cardimg" />
                      <img src={card5} className="cardimg" />
                    </div>
                    </div>
                <div className="guestfooterborder">
                  <div className="guestfooterrow">
                    <img src={verify1} className="verify1img" />
                    <img src={verify2} className="verify1img verify2img" />
                    <img src={verify3} className="verify1img verify3img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Room Details Modal */}
      <Modal
        show={showroomdetailsmodal}
        onHide={() => setshowroomdetailsmodal(false)}
        size="lg"
        className="custom-width-modal custom-width-modal-room"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 19 }}>{modaltitle} | {modalvarianttitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="webadmin-modalmainbody">
            <div className="viewdetailsmodalmaindiv row">
              <div className="col-md-6 order-2 order-md-1">
                <p className="canceltitle">Cancellation Rules</p>
                <p className="canceldescription">Free cancellation till 24 hours before check-in. If the cancellation is done within 24 hours of check-in 1 night tariff will be deducted and remaining would be refunded. If the guest is a no show the booking amount will be 100% chargeable and Payment Gateway charges of 3 % are non-refundable.</p>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <div className="viewdetailspayblock">
                  <div className="viewdetailspayrow">
                    <p className="viewdetailspaykey">Sub Total</p>
                    <p className="viewdetailspaykey" style={{fontWeight: '600'}}>INR {(modalsubtotal).toFixed(2)}</p>
                  </div>
                  <div className="viewdetailspayrow">
                    <p className="viewdetailspaykey">Taxes and Fees</p>
                    <p className="viewdetailspaykey" style={{fontWeight: '600'}}>INR {(modaltaxamount).toFixed(2)}</p>
                  </div>
                  <hr className="viewdetailspayrowhr" />
                  <div className="viewdetailspayrow">
                    <p className="viewdetailspaykey viewdetailspaykeytotal">Grand Total</p>
                    <p className="viewdetailspaykey viewdetailspaykeytotal" style={{fontWeight: '600'}}>INR {((parseInt(modaltaxamount) + parseInt(modalsubtotal))).toFixed(2)}</p>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Enhance Modal */}
      <Modal
        show={showenhancedetailsmodal}
        onHide={() => setshowenhancedetailsmodal(false)}
        size="md"
        className="custom-width-modal custom-width-modal-room"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 19 }}>{modalenhancetitle} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="webadmin-modalmainbody">
            <div className="viewdetailsmodalmaindiv row">
              <div className="col-md-6">
                    <div
                      className="htmlcontent enhancehtmlcontent"
                      dangerouslySetInnerHTML={{
                        __html: modalenhancedesc,
                      }}
                    />
                {/* <p className="canceldescription">{modalenhancedesc}</p> */}
              </div>
             
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Home;
